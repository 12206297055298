/**
 * Función para descargar un archivo PDF desde un archivo binario.
 * @param {string} name - El nombre del archivo a descargar.
 * @param {string} file - El archivo codificado en base64 (cadena binaria).
 */
export function downloadPDF(name, file) {
  // Decodificar la cadena base64
  const binaryString = atob(file);
  const bytes = new Uint8Array(binaryString.length);

  // Convertir la cadena binaria a un array de bytes
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Crear un Blob del archivo PDF
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Crear una URL para el Blob
  const url = URL.createObjectURL(blob);

  // Crear un enlace de descarga temporal
  const link = document.createElement('a');
  link.href = url;
  link.download = name;  // Nombre del archivo para la descarga

  // Agregar el enlace al DOM, hacer clic en él para descargar y luego eliminarlo
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Revocar la URL creada
  URL.revokeObjectURL(url);
}
