import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'
import { downloadPDF } from 'components/utils'
import { API_URL } from 'constants/index'

export const GET_CUSTOMERS_WITH_CREDITS = "[CUSTOMER CREDITS] GET CUSTOMERS WITH CREDITS"

export const GET_CUSTOMER_CREDITS = "[CUSTOMER CREDITS] GET CUSTOMER CREDITS"

export const CRUD_CUSTOMER_CREDIT = "[CUSTOMER CREDITS] CRUD CUSTOMER CREDIT"

export const DOWNLOADING_CUSTOMER_ACCOUNT_STATEMENT = "[CUSTOMER CREDITS] DOWNLOADING CUSTOMER ACCOUNT STATEMENT"

export const GET_CUSTOMER_COLLECTIONS = "[CUSTOMER COLLECTIONS] GET CUSTOMER COLLECTIONS"

export const GET_RECENT_CUSTOMER_COLLECTIONS = "[CUSTOMER COLLECTIONS] GET RECENT CUSTOMER COLLECTIONS"

export const CRUD_CUSTOMER_COLLECTION = "[CUSTOMER COLLECTIONS] CRUD CUSTOMER COLLECTION"

export function getCustomersWithCredits() {
  const request = axios.get(`${API_URL}/api/credito-cliente/clientes-con-creditos`)
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: false, data: response.data.detalles } })
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMERS_WITH_CREDITS, payload: { loading: false, data: [] } })
      toast.error("No se pudo obtener a los clientes con créditos")
      console.log(error)
    })
  }
}

export function getCustomerCredits({ customerId = 0, status = '' }) {
  const request = axios.get(`${API_URL}/api/credito-cliente/creditos-por-cliente`, {
    params: { cliente_id: customerId, estado: status }
  })
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMER_CREDITS, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener a los creditos del cliente")
      console.log(error)
    })
  }
}

export function deleteCustomerCredit(form) {
  const request = axios.post(`${API_URL}/api/credito-cliente/anular-credito`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_CUSTOMER_CREDIT, payload: { loading: false, crud: false } })
      toast.error("No se pudo anular el crédito")
      console.log(error)
    })
  }
}

export function downloadCustomerAccountStatementPdf({ customerId = 0, startDate = '', endDate = '', status = '' }) {
  const request = axios.get(`${API_URL}/api/credito-cliente/pdf-estado-cuenta-cliente`, {
    params: {
      cliente_id: customerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
    },
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_CUSTOMER_ACCOUNT_STATEMENT, payload: true })
    request.then((response) => {
      downloadPDF(response.data.nombre, response.data.archivo)
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_CUSTOMER_ACCOUNT_STATEMENT, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_CUSTOMER_ACCOUNT_STATEMENT, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function getCustomerCollections(
  { page = 1, pageSize = 10, customerId = 0, startDate = '', endDate = '', status = '', search = '', }
) {
  const request = axios.get(`${API_URL}/api/cobro-cliente/obtener-cobros-por-cliente`, {
    params: {
      page,
      por_pagina: pageSize,
      cliente_id: customerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_CUSTOMER_COLLECTIONS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_CUSTOMER_COLLECTIONS, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_CUSTOMER_COLLECTIONS, payload: { loading: false } })
      console.log(error)
    })
  }
}

export function getRecentCustomerCollections({ customerId = 0, limit = 5 }) {
  const request = axios.get(`${API_URL}/api/cobro-cliente/obtener-cobros-recientes-por-cliente`, {
    params: { cliente_id: customerId, limite: limit }
  })
  return (dispatch) => {
    dispatch({ type: GET_RECENT_CUSTOMER_COLLECTIONS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_RECENT_CUSTOMER_COLLECTIONS, payload: { loading: false, data: response.data.detalles } })

      setTimeout(() => {
        dispatch({ type: GET_RECENT_CUSTOMER_COLLECTIONS, payload: { loading: false, data: null } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: GET_RECENT_CUSTOMER_COLLECTIONS, payload: { loading: false, data: null } })
      toast.error("No se pudo obtener los cobros recientes del cliente")
      console.log(error)
    })
  }
}

export function saveCustomerCollection(form) {
  const request = axios.post(`${API_URL}/api/cobro-cliente/guardar-cobro`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
      toast.error("No se pudo registrar el cobro")
      console.log(error)
    })
  }
}

export function deleteCustomerCollection(form) {
  const request = axios.post(`${API_URL}/api/cobro-cliente/anular-cobro`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      if (parseInt(response.data.status) !== 200) {
        dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
        toast.error(response.data.mensaje)
        return
      }

      dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
      }, 50)
      return
    }).catch((error) => {
      dispatch({ type: CRUD_CUSTOMER_COLLECTION, payload: { loading: false, crud: false } })
      toast.error("No se pudo anular el cobro")
      console.log(error)
    })
  }
}